<template>
  <table class="table-fixed border border-borderColor capitalize">
    <thead class="w-full font-semibold text-left">
    <tr class="rounded-md">
      <th class="w-1/5 border border-borderColor py-4 px-5">month</th>
      <th class="w-1/5 border border-borderColor py-4 px-2">Operating Expenditure (₦)</th>
      <th class="w-1/5 border border-borderColor py-4 px-2">Operating Expenditure Bal. (₦)</th>
      <th class="w-1/5 border border-borderColor py-4 px-2">Capital Expenditure (₦)</th>
      <th class="w-1/5 border border-borderColor py-4 px-2">Capital Expenditure Bal. (₦)</th>
    </tr>
    </thead>
    <tbody v-if="budget" class="text-sm">
    <tr v-for="monthly_budget in budget.monthly_budget" :key="monthly_budget.id">
      <td class="border border-borderColor py-4 px-5">{{ monthly_budget.month }}</td>
      <td class="border border-borderColor py-4 px-5">
        {{ $filters.currencyUSD(monthly_budget.operating_expenses) }}
      </td>
      <td class="border border-borderColor py-4 px-5">2,900,000</td>
      <td class="border border-borderColor py-4 px-5">{{ $filters.currencyUSD(monthly_budget.capital_expenses) }}</td>
      <td class="border border-borderColor py-4 px-5">4,650,000</td>
    </tr>
    </tbody>
  </table>
</template>

<script>
export default {
  props: ['budget']
}
</script>
