<template>
  <LoadingSpinner v-if="!expenditure" />
  <main v-else>
    <BackArrow :pageTitles="{ heading: expenditure.product_name, content: 'BACK TO REQUESTS' }" />
    <success-toast :successMessage="successMessage" @resetSucessMessage="successMessage = null">
        <template v-slot:success-message>
            <span class="pl-2 text-md">{{ successMessage }}</span>
        </template>
    </success-toast>
    <error-toast :errorMessage="errorMessage" @resetErroMessage="errorMessage = null">
      <template v-slot:error-message>
        <span class="pl-2 text-md">{{ errorMessage }}</span>
      </template>
    </error-toast>
    <section class="flex items-start justify-between mx-32 text-white text-sm">
      <div class="w-1/4 h-28 flex flex-col p-8 rounded bg-navBgColor mr-8 tracking-widest">
        <span>Expenditure requests</span>
        <span class="text-2xl leading-loose">{{ expenditure.department_expenditure_request_count }}</span>
      </div>
      <div class="w-1/4 h-28 flex flex-col p-8 rounded bg-navBgColor mr-8 tracking-widest">
        <span>Capital Expenditure</span>
        <span class="text-2xl leading-loose">{{ $filters.currencyUSD(expenditure.annual_budget.capital_expenses) }}</span>
      </div>
      <div class="w-1/4 h-28 flex flex-col p-8 rounded bg-navBgColor mr-8">
        <span>Operating Expenditure </span>
        <span class="text-2xl leading-loose">{{ $filters.currencyUSD(expenditure.annual_budget.operating_expenses) }}</span>
      </div>
      <div @click="showBudgetBreakdown = true" class="w-1/4 h-28 flex flex-col justify-between cursor-pointer">
        <div class="w-full h-28 text-center p-8 rounded border border-primaryColor text-primaryColor text-md uppercase tracking-widest">
          view department budget breakdown
        </div>
      </div>
    </section>
    <section class="mx-32 my-12 pb-8 bg-white rounded shadow">
      <div class="flex flex-wrap p-10">
        <div class="w-1/4 py-4 px-3">
          <h1 class="text-primaryColor text-sm capitalize leading-loose font-bold">submitted by</h1>
          <div class="leading-loose">{{ expenditure.created_by }}</div>
        </div>
        <div class="w-1/4 py-4 px-3">
          <h1 class="text-primaryColor text-sm capitalize leading-loose font-bold">department</h1>
          <div class="leading-loose">{{ expenditure.department }}</div>
        </div><div class="w-1/4 py-4 px-3">
          <h1 class="text-primaryColor text-sm capitalize leading-loose font-bold">item name</h1>
          <div class="leading-loose">{{ expenditure.product_name }}</div>
        </div>
        <div v-if="expenditure.type_of_purchase === 'product_purchase'" class="w-1/4 py-4 px-3">
          <h1 class="text-primaryColor text-sm capitalize leading-loose font-bold">Are Goods Presently in Stock?</h1>
          <div class="leading-loose">{{ expenditure.is_product_in_stock }}</div>
        </div>
        <div v-if="expenditure.type_of_purchase === 'product_purchase'" class="w-1/4 py-4 px-3">
          <h1 class="text-primaryColor text-sm capitalize leading-loose font-bold">Deployment Location</h1>
          <div class="leading-loose">{{ expenditure.deployment_location }}</div>
        </div>
        <div v-if="expenditure.type_of_purchase === 'product_purchase'" class="w-1/4 py-4 px-3">
          <h1 class="text-primaryColor text-sm capitalize leading-loose font-bold">Do we Really Need the Goods/Services</h1>
          <div class="leading-loose">{{ expenditure.need_product }}</div>
        </div>
        <div v-if="expenditure.type_of_purchase === 'product_purchase'" class="w-1/4 py-4 px-3">
          <h1 class="text-primaryColor text-sm capitalize leading-loose font-bold">Is Expenditure Budget?</h1>
          <div class="leading-loose">{{ expenditure.expenditure_budget }}</div>
        </div>
        <div v-if="expenditure.type_of_purchase === 'product_purchase'" class="w-1/4 py-4 px-3">
          <h1 class="text-primaryColor text-sm capitalize leading-loose font-bold">Suggested Supplier</h1>
          <div class="leading-loose">{{ expenditure.suggested_supplier }}</div>
        </div>
        <div v-if="expenditure.type_of_purchase === 'product_purchase'" class="w-1/4 py-4 px-3">
          <h1 class="text-primaryColor text-sm capitalize leading-loose font-bold">Unit Price</h1>
          <div class="leading-loose">N {{ $filters.currencyUSD(expenditure.unit_cost) }}</div>
        </div>
        <div v-if="expenditure.type_of_purchase === 'product_purchase'" class="w-1/4 py-4 px-3">
          <h1 class="text-primaryColor text-sm capitalize leading-loose font-bold">Quantity</h1>
          <div class="leading-loose">{{ expenditure.quantity }}</div>
        </div>
        <div class="w-1/4 py-4 px-3">
          <h1 class="text-primaryColor text-sm capitalize leading-loose font-bold">Total cost</h1>
          <div class="leading-loose">N {{ $filters.currencyUSD(expenditure.total_cost) }}</div>
        </div>
      </div>
      <hr>
      <section class="px-10 py-5">
        <div class="py-5">
          <h1 class="text-primaryColor text-sm leading-loose">Item Description</h1>
          <div class="leading-loose pr-32">{{ expenditure.product_description }}</div>
        </div>
        <div v-if="expenditure.type_of_purchase === 'product_purchase'" class="py-5">
          <h1 class="text-primaryColor text-sm leading-loose">Procurement Strategy</h1>
          <div class="leading-loose pr-32">{{ expenditure.procurement_strategy }}</div>
        </div>
      </section>
      <hr v-if="expenditure.expenditure_request_files.length">
      <section v-if="expenditure.expenditure_request_files.length" class="px-10 py-12 grid grid-cols-8 gap-1">
        <div v-for="(file, index) in expenditure.expenditure_request_files" :key="index" class="w-28 h-28 cursor-pointer">
            <div @click="openDocument(file.file_url)" class="flex items-center justify-center w-full h-full border border-borderColor rounded">
                <img v-if="imageFileType.includes(file.file_url.substr(file.file_url.length - 4)) || imageFileType.includes(file.file_url.substr(file.file_url.length - 3))" src="@/assets/images/image-format-icon.svg" alt="image icon" :data-fileurl="file.file_url" class="w-1/2">
                <img v-if="pdfFileType.includes(file.file_url.substr(file.file_url.length - 3))" src="@/assets/images/pdf-document-icon.svg" alt="pdf document icon" :data-fileurl="file.file_url" class="w-1/2">
                <img v-if="wordFileType.includes(file.file_url.substr(file.file_url.length - 3)) || wordFileType.includes(file.file_url.substr(file.file_url.length - 4))" src="@/assets/images/word-document-icon.svg" alt="word document icon" :data-fileurl="file.file_url" class="w-1/2">
            </div>
            <div @click="openDocument(file.file_url)" class="w-28 text-xs py-3">{{ file.file_name }}</div>
        </div>
      </section>
      <hr v-if="isMyDepartment">
      <section v-if="isMyDepartment" class="px-10 py-12 pr-32 leading-loose">
        <h1 class="capitalize">comment <span class="lowercase">(optional)</span></h1>
        <textarea cols="30" v-model="comment" rows="5" class="block w-full border border-borderColor rounded resize-none p-4"></textarea>
        <div class="flex flex-row text-white py-12 justify-end">
            <div @click="approveExpenditureRequest" class="py-2 px-4 mr-3.5 text-center bg-pillApprovedStatus text-sm rounded cursor-pointer">Approve Expenditure</div>
            <div @click="rejectExpenditureRequest" class="py-2 px-4 text-center bg-pillRejectedStatus text-sm rounded cursor-pointer">Reject Expenditure</div>
        </div>
      </section>
    </section>
    <section v-if="showBudgetBreakdown" class="w-screen h-screen fixed top-0 z-50 flex items-center justify-center py-12" style="background-color: rgba(0,0,0,0.2); overflow: auto; overscroll-y: contain;">
      <div class="bg-white w-4/5 overflow-y-scroll overscroll-contain rounded shadow p-16 relative" style="height: 90vh">
        <BudgetDataTable :budget="expenditure.annual_budget" />
        <img @click="showBudgetBreakdown = false" src="@/assets/images/close-icon.svg" class="absolute top-6 right-12 cursor-pointer" />
      </div>
    </section>
  </main>
</template>

<script>
import Request from '@/services/requestHelper'
import BackArrow from '@/components/ui/BackArrow.vue'
import BudgetDataTable from '@/components/BudgetDataTable.vue'
import { mapState } from 'vuex'

export default {
  props: { id: String },
  computed: {
    ...mapState(['user']),
    isMyDepartment() {
      let isMyDepartment

      if (this.user && this.expenditure) {
        isMyDepartment = this.user.data.department.toLowerCase() === this.expenditure.department.toLowerCase()
      }

      return isMyDepartment
    }
  },
  data() {
    return {
      showBudgetBreakdown: false,
      expenditure: null,
      errorMessage: null,
      successMessage: null,
      budget: null,
      emptyBudget: false,
      comment: null,
      imageFileType: ['png', 'jpg', 'jpeg'],
      pdfFileType: ['pdf'],
      wordFileType: ['docx', 'doc', 'txt', 'xlsx', 'xls', 'csv']
    }
  },
  components: {
    BackArrow, BudgetDataTable
  },
  async mounted() {
      try {
          const { status, data } = await Request.getRequest(`expenditure/request/${this.id}`)
          if (status === 200) {
            this.expenditure = data.data
          }
      } catch (error) {
        this.errorMessage = error.response.data.message
      }
  },
  methods: {
    openDocument(fileUrl) {
        window.open(`http://finance-api.suburbanfiberco.com${fileUrl}`, '_blank')
    },
    async approveExpenditureRequest() {
      try {
        let url
        if (this.user.data.position === 'CEO' || this.user.data.position === 'COO') {
          url = 'expenditure/request/management/approve'
        } else {
          url = 'expenditure/request/line-manager/approve'
        }
        const { status, data } = await Request.postRequest(`${url}?expenditure_request_id=${this.expenditure.id}&comment=${this.comment}`)

        if (status === 201) {
          this.successMessage = data.message
          setTimeout(() => {
            this.$router.push({ name: 'Expenditure' })
          }, 5000)
        }
      } catch (error) {
        this.errorMessage = error.response.data.message
      }
    },
    async rejectExpenditureRequest() {
      try {
        let url
        if (this.user.data.position === 'CEO' || this.user.data.position === 'COO') {
          url = 'expenditure/request/management/reject'
        } else {
          url = 'expenditure/request/line-manager/reject'
        }
        const { status, data } = await Request.postRequest(`${url}?expenditure_request_id=${this.expenditure.id}&comment=${this.comment}`)

        if (status === 201) {
          this.successMessage = data.message
          setTimeout(() => {
            this.$router.push({ name: 'Expenditure' })
          }, 5000)
        }
      } catch (error) {
        this.errorMessage = error.response.data.message
      }
    },
    async getBudgetByDepartment(department) {
      try {
        const { data } = await Request.getRequest(`budget/department/${department}/year/${this.year}`)

        this.budgetDepartment = data.data.department
        this.budget = data.data
        this.selectDomElement()
      } catch (error) {
        this.errorMessage = error.response.data.message
        this.budget = null
        this.emptyBudget = true
      }
    }
  }
}
</script>
